import React, { Component } from 'react';
import { Container, Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';

import lady from '../../img/lady.png'

class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      appointment: false,
      mailSent: false,
      name: '',
      phone: '',
      email: '',
      service: '',
      date: '',
      time: '',
      notes: ''
    }
    this.toggleAppointment = this.toggleAppointment.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  toggleAppointment(){
    this.setState({
      appointment: !this.state.appointment,
      mailSent: false});
  }

  handleChange(event){
    this.setState({ [event.target.name]: (event.target.type === 'checkbox' ? event.target.checked : event.target.value)})
  }

  handleSubmit(event){
    event.preventDefault();
    fetch('http://www.grailsoft.com/sup.php', {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    })
    .then((res) => res.json())
    .then(result => {
        console.log(result)
        this.setState({
          mailSent: result.data
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({ error: error.message })
      }
    );
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    if(this.formCheck()){
      fetch('https://www.hairlairpdx.com/mail_handler.php', {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.state)
      })
      .then((res) => res.json())
      .then(result => {
          console.log(result)
          this.setState({
            mailSent: result.sent
          })
          console.log("state change");
          this.forceUpdate();
          console.log(this.state);
        })
        .catch(error => {
          console.log(error)
          this.setState({ error: error.message })
        }
      );
    }
    console.log(this.state);
  }

  formCheck(){
    if(this.state.name === ''){
      alert('Please enter your name');
      return false;
    }
    if(this.state.phone.length > 10){
      alert('Please enter a valid phone number');
      return false;
    }
    if(!this.state.email.includes('@')){
      alert('Please enter a valid email address');
      return false;
    }
    if(this.state.service === ''){
      alert('Please select a service');
      return false;
    }
    if(this.state.date === '' && this.state.day === ''){
      alert('Please select a date');
      return false;
    }
    if(this.state.time === ''){
      alert('Please select a time');
      return false;
    }
    return true;
  }

  render(){
    return (
      <div style={{height: 'auto', minHeight: '100vh'}}>
        <Container className="Home align-items-stretch">
          <Row className="align-items-center justify-content-center" style={{height: 'auto'}}>
            <Col lg="4">
              <div id="logo">
                <h2>The Hair Lair</h2>
                <p>est. 2009</p>
                <img id='lady' src={lady} alt="lady"/>
              </div>
            </Col>
            <Col lg="8" style={{display: ((!this.state.appointment && !this.state.mailSent) ? "block" : "none")}}>
              <Container className="Menu">
                <Row className="align-items-center justify-content-center">
                  <Col md="12">
                    <h2>Hours</h2>
                  </Col>
                  <Col md="12" id="hours">
                    <p>Mon: 11:00AM - 7:00PM</p>
                    <p>Tue: 11:00AM - 7:00PM</p>
                    <p>Wed: 11:00AM - 7:00PM</p>
                    <p>Thu: 11:00AM - 7:00PM</p>
                    <p>Fri: 11:00AM - 7:00PM</p>
                    <p>Sat: CLOSED</p>
                    <p>Sun: CLOSED</p>
                  </Col>
                  <Col md="12">
                    <h2>Services</h2>
                  </Col>
                  <Col md="4">
                    <p>Short Hair Cut - $35</p>
                  </Col>
                  <Col md="4">
                    <p>Long Hair Cut - $45</p>
                  </Col>
                  <Col md="4">
                    <p>Buzz Cut - $20</p>
                  </Col>
                  {/* <Col md='12' style={{display: 'flex', justifyContent: 'space-between'}}>
                    <i className="far fa-snowflake"></i>
                    <h4 style={{paddingTop: '.25rem', fontStyle: 'italic'}}>STAY COOL</h4>
                    <h4 style={{paddingTop: '.25rem', fontStyle: 'italic'}}>WE'VE GOT AC</h4>
                    <i className="far fa-snowflake"></i>
                  </Col> */}
                  <Col md="12">
                    <h2>Find and Contact</h2>
                  </Col>
                  <Col md="4" id="location" style={{paddingTop: '10px'}}>
                    <p>3332 SW Corbett Ave</p>
                    <p>Portland, OR 97239</p>
                  </Col>
                  <Col md="4" style={{paddingTop: '10px'}}>
                    <Button color="success"><a href="tel:+15032220660">(503)-222-0660&emsp;<span className="fas fa-phone" /></a></Button>
                  </Col>
                  <Col md="6" style={{paddingTop: '10px'}}>
                    <Button color="success" onClick={this.toggleAppointment}>Request Appointment&emsp;<span className="fas fa-calendar-check" /></Button>
                  </Col>
                  <Col md="12" style={{paddingTop: '10px'}}>
                    <h1>
                      <span className="fab fa-cc-visa"></span>&emsp;
                      <span className="fab fa-cc-discover"></span>&emsp;
                      <span className="fab fa-cc-mastercard"></span>&emsp;
                      <span className="fab fa-cc-amex"></span>&emsp;
                      <span className="fab fa-cc-apple-pay"></span>&emsp;
                      <span className="fab fa-google-wallet"></span>
                    </h1>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md="8" style={{display: ((this.state.appointment && !this.state.mailSent) ? "block" : "none")}}>
              <Container>
                <Row className="align-items-center justify-content-center">
                  <Col md="12">
                    <Form>
                      <FormGroup>
                        <Label for="customerName">Name</Label>
                        <Input type="text" name="name" id="customerName" placeholder="Your Name" onChange={this.handleChange} required></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="customerPhone">Phone</Label>
                        <Input type="tel" name="phone" id="customerPhone" placeholder="" onChange={this.handleChange} required></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="customerEmail">Email</Label>
                        <Input type="email" name="email" id="customerEmail" placeholder="" onChange={this.handleChange} required></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="appointmentType">Appointment Type</Label>
                        <Input type="select" name="service" id="appointmentType" onChange={this.handleChange} required>
                          <option>Select a Service</option>
                          <option value="Short Hair Cut">Short Hair Cut - $35</option>
                          <option value="Long Hair Cut">Long Hair Cut - $45</option>
                          <option value="Buzz Cut">Buzz Cut - $20</option>
                          {/* <option value="Highlights">Highlights - $100</option>
                          <option value="Retouch Single Color">Retouch Single Color - $60</option>
                          <option value="Bleach Retouch">Bleach Retouch - $70</option>
                          <option value="Balayage">Balayage (Requires Consultation) - $150+</option>
                          <option value="All Over Color">All Over Color - $100</option> */}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="appointmentDate">Date</Label>
                        <Input type="date" name="date" id="appointmentDate" placeholder="date placeholder" onChange={this.handleChange}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="appointmentTime">Time(s)</Label>
                        <Input type="select" name="time" id="appointmentTime" onChange={this.handleChange} required>
                          <option>Select a time</option>
                          <option value="11:00AM">11:00AM</option>
                          <option value="11:15AM">11:15AM</option>
                          <option value="11:30AM">11:30AM</option>
                          <option value="11:45AM">11:45AM</option>
                          <option value="12:00PM">12:00PM</option>
                          <option value="12:15PM">12:15PM</option>
                          <option value="12:30PM">12:30PM</option>
                          <option value="12:45PM">12:45PM</option>
                          <option value="1:00PM">1:00PM</option>
                          <option value="1:15PM">1:15PM</option>
                          <option value="1:30PM">1:30PM</option>
                          <option value="1:45PM">1:45PM</option>
                          <option value="2:00PM">2:00PM</option>
                          <option value="2:15PM">2:15PM</option>
                          <option value="2:30PM">2:30PM</option>
                          <option value="2:45PM">2:45PM</option>
                          <option value="3:00PM">3:00PM</option>
                          <option value="3:15PM">3:15PM</option>
                          <option value="3:30PM">3:30PM</option>
                          <option value="3:45PM">3:45PM</option>
                          <option value="4:00PM">4:00PM</option>
                          <option value="4:15PM">4:15PM</option>
                          <option value="4:30PM">4:30PM</option>
                          <option value="4:45PM">4:45PM</option>
                          <option value="5:00PM">5:00PM</option>
                          <option value="5:15PM">5:15PM</option>
                          <option value="5:30PM">5:30PM</option>
                          <option value="5:45PM">5:45PM</option>
                          <option value="6:00PM">6:00PM</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="appointmentNotes">Notes</Label>
                        <Input type="textarea" name="notes" id="appointmentNotes" placeholder="Additional information or requests" onChange={this.handleChange}></Input>
                      </FormGroup>
                      <Button color="success" onClick={this.handleFormSubmit}>Book Now</Button>
                    </Form>
                  </Col>
                  <Col md="4">
                    <Button color="danger" onClick={this.toggleAppointment}>Cancel</Button>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md="8" style={{display: ((this.state.appointment && this.state.mailSent) ? "block" : "none")}}>
              <Container>
                <Row className="align-items-center justify-content-center">
                  <Col md="12">
                    <h3>Your request has been submitted!</h3>
                    <h3>We'll get back to you soon to confirm your appointment.</h3>
                  </Col>
                  <Col md="4">
                    <Button color="primary" onClick={this.toggleAppointment}>Return</Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

    );
  }
}

export default Home;
